import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import * as Styled from './index.styles';
import { OutlineButton } from 'components/atoms/Button';
import { useUser } from 'hooks/useUser';
import { InputRef } from 'components/atoms/Input';
import { useMutation } from '@apollo/client';
import USER_QUERY from 'graphql/user';
import { PrevHeader } from 'components';
import ConfirmPhoneNum from 'components/molecules/ConfirmPhoneNum';
import { convertSocialName } from 'utils/common';
import { deleteCookie } from 'utils/cookie';
import { resetAuth } from 'modules/auth/auth.actions';

const Index = ({ match }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { user } = useUser();
  const [inputEmail, setInputEmail] = useState(user.email);
  const [inputName, setInputName] = useState(user.name);
  const [inputOkPhone, setInputOkPhone] = useState(user.phonenum);
  const [isChangeInfo, setIsChangeInfo] = useState(false);
  const [agreeMarketingInform, setAgreeMarketingInform] = useState(user.agreeMarketingInform);
  const [pinOk, setPinOk] = useState(false);

  // UPDATE_USER
  const [updateUser] = useMutation(USER_QUERY.UPDATE_USER);

  // useEffect(() => {
  //   if (!inputEmail) {
  //     setInputEmail(user.email);
  //     setInputName(user.name);
  //     setInputOkPhone(user.phonenum);
  //     setAgreeMarketingInform(user.agreeMarketingInform);
  //   }
  // }, [inputEmail, user]);

  useEffect(() => {
    if (
      inputEmail !== user.email ||
      inputName !== user.name ||
      pinOk ||
      agreeMarketingInform !== user.agreeMarketingInform
    ) {
      setIsChangeInfo(true);
    } else {
      setIsChangeInfo(false);
    }
  }, [
    inputEmail,
    inputName,
    pinOk,
    agreeMarketingInform,
    user.email,
    user.name,
    user.agreeMarketingInform,
  ]);

  const logout = () => {
    if (window.confirm('로그아웃하시겠습니까?')) {
      dispatch(resetAuth());
      deleteCookie({ name: 'token' }, () => {
        window.location = '/';
      });
    }
  };

  return (
    <Styled.Container>
      <Styled.Header>
        <PrevHeader />
      </Styled.Header>
      <Styled.TitleBig>내 정보</Styled.TitleBig>
      <Styled.Description d-flex align-items-center>
        <Styled.SocialLogo className={`svg-sns-${user.provider}`} social={user.provider} />
        {convertSocialName(user.provider)}(으)로 가입
      </Styled.Description>

      <div style={{ marginTop: '60px' }}>
        <div>
          <Styled.InputWrap>
            <Styled.InputLabel>이메일</Styled.InputLabel>
            <InputRef
              placeholder="이메일을 입력해주세요"
              value={inputEmail}
              onChange={(e) => {
                setInputEmail(e.target.value);
              }}
              // inputRef={el => name = el}
              border-round
            />
          </Styled.InputWrap>
          <Styled.InputWrap style={{ marginTop: '30px' }}>
            <Styled.InputLabel>이름 (필수)</Styled.InputLabel>
            <InputRef
              placeholder="이름을 입력해주세요"
              value={inputName}
              onChange={(e) => {
                setInputName(e.target.value);
              }}
              // inputRef={el => name = el}
              border-round
            />
          </Styled.InputWrap>
          <Styled.InputWrap style={{ marginTop: '30px' }}>
            <Styled.InputLabel>휴대폰 번호</Styled.InputLabel>
            <ConfirmPhoneNum
              userPhone={inputOkPhone}
              callback={(res, phone) => {
                if (res.ok) {
                  // alert('인증 완료');
                  if (!agreeMarketingInform) {
                    if (
                      window.confirm(
                        '놓치면 후회할 언에이징 맞춤 혜택\n\n지금 맞춤 혜택 및 광고성 정보 수신에 동의하시면 ' +
                          '나에게 맞는 혜택과 픽정보를 받으실 수 있어요'
                      )
                    ) {
                      setAgreeMarketingInform(true);
                    }
                  }
                  setPinOk(true);
                  setInputOkPhone(phone);
                } else {
                  setPinOk(false);
                }
              }}
            />
          </Styled.InputWrap>
          <Styled.InputWrap style={{ marginTop: '60px' }}>
            <OutlineButton
              onClick={() => {
                setAgreeMarketingInform(!agreeMarketingInform);
              }}
              checkbox
              className={`${agreeMarketingInform ? 'active' : 'inactive'}`}
            >
              맞춤 혜택 및 광고성 정보 수신 동의
            </OutlineButton>
          </Styled.InputWrap>
        </div>
      </div>
      <Styled.ButtonSave>
        <Styled.ButtonSaveInfo
          disabled={!isChangeInfo}
          onClick={async () => {
            await updateUser({
              variables: {
                email: inputEmail,
                name: inputName,
                phonenum: inputOkPhone,
                agreeMarketingInform,
              },
            })
              .then((res) => {
                const { updateUser } = res.data;
                if (updateUser) {
                  alert('내 정보를 수정하였습니다.');
                  window.location.reload();
                }
              })
              .catch((err) => {
                // PHONENUM_NOT_PROVIDE
                switch (err.message) {
                  case 'PHONENUM_NOT_PROVIDE':
                    alert('전화번호를 입력해 주세요.');
                    break;
                }
              });
          }}
        >
          저장하기
        </Styled.ButtonSaveInfo>
        {/* <BorderButton
          disabled={!isChangeInfo}
          bold
          fill
          containerFluid
          onClick={async () => {
            await updateUser({
              variables : {
                id: user.id,
                email: inputEmail,
                name: inputName,
                phonenum: inputOkPhone,
                agreeMarketingInform: agreeMarketingInform,
              }
            }).then(res => {
              const { updateUser } = res.data;
              if(updateUser) {
                alert('내 정보를 수정하였습니다.');
                window.location.reload();
              }
            })
              .catch((err) => {
                // PHONENUM_NOT_PROVIDE
                switch(err.message){
                  case 'PHONENUM_NOT_PROVIDE':
                    alert('전화번호를 입력해 주세요.')
                    break;
                }
              });
          }}

        >
          저장하기
        </BorderButton> */}
      </Styled.ButtonSave>
      <Styled.ButtonLogout>
        <span onClick={logout}>로그아웃</span>
      </Styled.ButtonLogout>
    </Styled.Container>
  );
};

// reducer 적용 필요
export default Index;
