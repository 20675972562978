import styled from 'styled-components';

export default function SectionI() {
  return (
    <Footer>
      <Row style={{ marginBottom: 0 }}>
        <Item
          margin
          style={{ cursor: 'pointer' }}
          onMouseUp={() =>
            window.open(
              'https://alike-leech-719.notion.site/97c71a602c5c43b481397f2e0d20ac62',
              '_blank'
            )
          }
        >
          이용약관
        </Item>
        <Item
          margin
          marginLeft
          style={{ cursor: 'pointer' }}
          onMouseUp={() =>
            window.open(
              'https://alike-leech-719.notion.site/b4d2caff3e2543fc8468b6a607406011',
              '_blank'
            )
          }
        >
          개인정보처리방침
        </Item>
        <Item
          marginLeft
          style={{ cursor: 'pointer' }}
          onMouseUp={() =>
            window.open(
              'https://alike-leech-719.notion.site/95742dd98ff2437f8135bf7c095b74a2',
              '_blank'
            )
          }
        >
          개인민감정보처리방침
        </Item>
        <Item
          className="highlight"
          marginLeft
          style={{ cursor: 'pointer' }}
          onMouseUp={() => window.open('https://contents.imweb.me/295')}
        >
          파트너 입점문의
        </Item>
      </Row>
      {/* <Logo src="/assets/images/logo.svg" alt="언에이징" /> */}
      <Logo src="/assets/images/logo-amuse.png" alt="어뮤즈케어 주식회사" />
      <Row>
        <Item margin>어뮤즈케어 주식회사</Item>
        <Item margin>대표 고석호</Item>
        <Item marginLeft>사업자번호 522-81-01167</Item>
      </Row>
      <Row>서울시 금천구 두산로70, b동 18층 1803-5호</Row>
      <Row>
        <Item margin>
          전화 <a href="tel:02-3443-1357">02-3443-1357</a> (평일 10:00 - 19:00)
        </Item>
        <Item marginLeft>
          이메일 <a href="mailto:master@amusecare.im">master@amusecare.im</a>
        </Item>
      </Row>
      <Row style={{ marginTop: '2rem' }}>© 2024 Amusecare all rights reserved.</Row>
    </Footer>
  );
}

const Footer = styled.footer`
  padding-top: 2rem;
  padding-bottom: 10rem;
`;

const Row = styled.div`
  margin-bottom: 0.5rem;
`;

const Item = styled.span`
  border-right: ${({ margin }) => margin && '1px solid #eee'};
  padding-right: 1rem;
  padding-left: ${({ marginLeft }) => marginLeft && '1rem'};

  a {
    color: #000;
    &:visited {
      color: inherit;
    }
  }
  &.highlight {
    margin-left: 1rem;
    position: relative;
    &:after {
      left: 0;
      position: absolute;
      width: 100%;
      height: 1.7rem;
      content: '';
      background-color: #e1ea68;
      z-index: -1;
      border-radius: 4px;
      top: -3px;
    }
  }
`;

const Logo = styled.img`
  width: 7rem;
  margin: 1.5rem 0;
  transform: translateX(-0.8rem);
`;
